@import "reset";


@import "_variables";
@import "_mixins";
@import "_fonts"; 
  
//desktop 

@import "_header";
@import "_home"; 
@import "_footer"; 

//pc 

@import "pc/_header";
@import "pc/_home"; 
@import "pc/_footer"; 


//portatil
@import "portatil/_header";
@import "portatil/_home"; 
@import "portatil/_footer"; 

//tablet
@import "tablet/_header";
@import "tablet/_home"; 



//mobile 768
@import "mobile_768/_header";
@import "mobile_768/_home"; 
@import "mobile_768/_footer"; 


//mobile
@import "mobile/_header";
@import "mobile/_home"; 
@import "mobile/_footer"; 

body{
    background:white;
    color:$gris;
    font-family: $helveticaRoman;
    font-size:get-vw(22px);
    line-height: get-vw(26px);
    text-align: center;
}

a{color:$azul;text-decoration: none;}
a:hover{color:$negro;}
img{max-width: 100%;}
.azul{color:$azul;}
.gris{color:$gris;}
.clear{clear:both;}
.degradado{   
 
	/*background: rgba(234,106,122,1);
	background: -moz-linear-gradient(left, rgba(234,106,122,1) 0%, rgba(234,106,122,1) 10%, rgba(234,106,122,1) 20%, rgba(44,61,143,1) 46%, rgba(44,61,143,1) 46%, rgba(44,61,143,1) 96%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(234,106,122,1)), color-stop(10%, rgba(234,106,122,1)), color-stop(20%, rgba(234,106,122,1)), color-stop(46%, rgba(44,61,143,1)), color-stop(46%, rgba(44,61,143,1)), color-stop(96%, rgba(44,61,143,1)));
	background: -webkit-linear-gradient(left, rgba(234,106,122,1) 0%, rgba(234,106,122,1) 10%, rgba(234,106,122,1) 20%, rgba(44,61,143,1) 46%, rgba(44,61,143,1) 46%, rgba(44,61,143,1) 96%);
	background: -o-linear-gradient(left, rgba(234,106,122,1) 0%, rgba(234,106,122,1) 10%, rgba(234,106,122,1) 20%, rgba(44,61,143,1) 46%, rgba(44,61,143,1) 46%, rgba(44,61,143,1) 96%);
	background: -ms-linear-gradient(left, rgba(234,106,122,1) 0%, rgba(234,106,122,1) 10%, rgba(234,106,122,1) 20%, rgba(44,61,143,1) 46%, rgba(44,61,143,1) 46%, rgba(44,61,143,1) 96%);
	background: linear-gradient(to right, rgba(234,106,122,1) 0%, rgba(234,106,122,1) 10%, rgba(234,106,122,1) 20%, rgba(44,61,143,1) 46%, rgba(44,61,143,1) 46%, rgba(44,61,143,1) 96%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea6a7a', endColorstr='#2c3d8f', GradientType=1 );*/
	
	background: linear-gradient(to left,$morado1, $morado2);
	
	color:#fff!important;
	a{color:#fff!important;}
}

@media screen and (max-width: 620px){
    body{

    font-size:get-vw-mobile(14px);
    line-height: get-vw-mobile(18px);
}
}

::-webkit-input-placeholder { 
    color:    $grisoscuro;
}
:-moz-placeholder { 
   color:    $grisoscuro;
   opacity:  1;
}
::-moz-placeholder {
   color:    $grisoscuro;
   opacity:  1;
}
:-ms-input-placeholder {
   color:    $grisoscuro;
}
::-ms-input-placeholder { 
   color:    $grisoscuro;
}
::placeholder { 
   color:    $grisoscuro;
}
.bloquecentrado{display: flex;justify-content: space-around;align-items: center;}
.bloqueexpandido{display: flex;justify-content: space-between;;align-items: center;}
.tama26{font-size: 26px!important;}
.mvlon{display: none;}
.mvloff{display: inherit;}
@media screen and (max-width: 900px){
    .mvlon{display: inherit;}
    .mvloff{display: none!important;}
    
    body{
    background:white;
    color:$gris;
    font-family: $helveticaRoman;
    font-size:get-vw-mvl(22px);
    line-height: get-vw-mvl(26px);
    text-align: center;
}
}

.checkboxs input[type="checkbox"] {
	display: none;
}
.checkboxs input[type="checkbox"] + label span {
	display: inline-block;
	width: 15px;
	height: 15px;
	margin: -1px 4px 0 0;
	vertical-align: middle;
	/*background: url(checkbox-uncheck.png);*/
	background-size: cover;
	cursor: pointer;
        border:1px solid #CCCCCC;
        border-radius: 2px;
}
.checkboxs input[type="checkbox"]:checked + label span {
	background: url(../img/check.png);
	background-size: cover;
}

.fondogrisclaro{background: $grisclaro;}
.fondoblanco{background: #fff;}
.fondorojo{background: $rojo!important;color:#fff!important;}
.grisoscuro{color:$grisoscuro;}
.btn:hover{-webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75)!important;}

.contentmenumvl a:hover{color:$rojo!important;}