#wraper{
    display:inline-block;
    text-align: center;
    width: 100%;
    .main-sectors{
        text-align: center;
        display: inline-block;
        width: 100%;
        max-width: get-vw(1680px);
        .content{
            display:inline-flex;
            text-align: left;;
            position:relative;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin: auto;
            align-items: top;
            margin-bottom: get-vw(40px);
            justify-content: space-between;
            .items{
                width: 100%;
                font-size: get-vw(23px);
                line-height: get-vw(26px);
                max-width: 48%;
                text-align: left;
                h2{
                    font-size: get-vw(50px);
                    line-height: get-vw(55px);
                    font-family: $helveticaBd;
                    color:$azul;
                    max-width: get-vw(683px);
                }
                p{
                    max-width: get-vw(683px);
                    font-size:get-vw(22px);
                    line-height: get-vw(35px);
                }
                .btn{
                    float:left;
                    padding: 10px 20px;
                    background: linear-gradient(to left,$morado1, $morado2);
                    border-radius: 30px;
                    color:#fff;
                    font-family: $helveticaBd;
                    margin-top: get-vw(50px);
                }
                .contenidoFondo{
                    position: absolute;
                    bottom: 0;

                    right: get-vw(20px);
                    margin-right: 48%;
                    max-width:100%;
                    p{
                        text-align: right;
                        font-size: get-vw(18px);
                        line-height: get-vw(25px);
                    }
                }
            }
        }
        .contentProceso{
            /*display:inline-flex;*/
            display: inline-block;
            text-align: left;
            position:relative;
            flex-wrap: nowrap;
            width:100%;
            margin: auto;
            align-items: flex-start;
            overflow-x: auto;
            position: relative;
            justify-content: flex-start;
            .bloques{
                 display: inline-block;
                width: 100%;

                vertical-align: top;
                max-width: get-vw(1620px);  ;
                .headerProceso{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    align-items: center;
                    width: 100%;
                    height: get-vw(100px);
                    justify-content: space-between;
                    color:$blanco;
					
					
                    h3{
                        font-size: get-vw(30px);
                        line-height: get-vw(35px);
                        font-family: $helveticaBd;
						color:$blanco;
                        padding: 10px 30px;
                        background: linear-gradient(to left,$morado1, $morado2);
                        border-radius: 30px;
						
						.flecha_volver{
							margin-right:20px;
						}
                                            
                    }
					
					.volver_estilo{
						background: $blanco;
						color:$morado2;
						border: 2px solid #740691;
			
					}
					
					
					
                }
                .provincias{
                    display: inline-block;
                    margin-top: get-vw(40px);
                    margin-bottom: get-vw(20px);
                    width: 100%;
                    select{
                        width: 100%;
                        height: get-vw(65px);
                        border:1px solid #CCCCCC;
                        border-radius: 3px;
                        font-size: get-vw(22px);
                        color:$grisoscuro;
                        background: $grisclaro;
                    }
                }
                h4{color:$grisoscuro;
                   font-size:get-vw(26px);
                   font-family: $helveticaBd;
                   margin-bottom: get-vw(10px);
                   margin-top: get-vw(10px);

				   .parentesis{
				   	font-weight: normal;
				    font-size:get-vw(20px);
					font-family: "HelveticaNeueLTStd-Roman";
				   }
                }
                h5{font-size: 22px;padding: get-vw(20px);width: 100%;}
                .listado{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 100%;
                    .linea{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        color:$grisoscuro;
                        position: relative;
                        border:1px solid #CCCCCC;
                        border-bottom:none;
                        &:last-child{border-bottom:1px solid #CCCCCC;}
                        h4{
                            margin-left:20px;
                        }
                        .contentVariaciones{
                            display: inline-block;
                            width: 100%;
                        }
                        .esquinaverde{
                            position: absolute;
                            right: 0;
                            top: 0;
                            z-index: 9;
                        }
                        .loguillo{
                            width: 100%;
                            max-width: get-vw(183px);
                            border-right: 1px solid #CCCCCC;
                            float:left;
                            vertical-align: top;
                            div{
                                text-align: center;
                                font-size: 16px;
                            }
                        }
                        .variaciones{
                            width: 100%;
                            max-width: 78%;
                            display: inline-flex;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            vertical-align: top;
                            align-items: flex-start;
                            .bloquecampos{
                                width: 100%;
                                max-width:48%;
                                display: flex;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                align-items: flex-start;
                                align-items: center;

                                font-size: get-vw(18px);
                                .btn{
                                    font-size: get-vw(22px);
                                    float:right;
                                    text-align: center;
                                    padding: get-vw(10px) get-vw(20px);
									padding: 10px 20px;
									background: linear-gradient(to left,$morado1, $morado2);
									border-radius: 30px;
                                    color:#fff;
                                    font-family: $helveticaBd;
                                     width: get-vw(180px);
                                }
                                input{
                                    width: get-vw(290px);
                                    height: get-vw(60px);
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;
                                    background: $grisclaro;
									padding-left:18px;
                                }
                                select{
                                    width: get-vw(312px);
                                    height: get-vw(65px);
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;




                                }
                                label{margin-right:get-vw(10px); }
                                .coste{
                                    font-size: get-vw(26px);
                                    font-family: $helveticaBd;
                                    margin-bottom: 0px;
                                    color: $rojo;
                                    span{font-size:get-vw(15px); font-family: $helveticaRoman;color:$gris;margin-top: -5px;float:left; }
                                }
                            }
                        }
                        .variacion1{padding: get-vw(20px) get-vw(20px) get-vw(5px) get-vw(20px);}
                        .variacion2{padding: get-vw(15px) get-vw(20px) get-vw(20px) get-vw(20px);}
                    }
                    .activo{
                        /*background:url(../img/esquinaverde.png)  no-repeat top right;*/
                        max-width: 99.9%;
                        border:2px solid $verde;
                        .esquinaverde{display: inherit!important;}
                        &:last-child{border-bottom:2px solid $verde;;}
                        .btn{
                                    background:#CCCCCC!important;
                                }
                        .coste{
                                    color:$grisoscuro!important;
                                }
                    }
                }
                .contentPartes{
                    display:flex;
                    text-align: left;
                    position:relative;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    width: 98%;
                    padding: 2%;
                    padding-right:0px;
                    .partes{
                        width: 96%;
                        .campo{
                            input{
                              width: get-vw(555px);
                              height: get-vw(40px);
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;
                                    background: $grisclaro;
                                    padding: get-vw(10px);
                            }
                            textarea{
                                    width: get-vw(555px);
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;
                                    background: $grisclaro;
                                    font-family: $helveticaRoman;
                                    padding: get-vw(10px);
                            }
                        }
                        .alto{
                            margin-top:  get-vw(20px);
                            margin-bottom: get-vw(20px);
                                textarea{
                                    height: get-vw(132px);
                                }
                            }
                        .medio{

                            margin-bottom: get-vw(20px);
                            textarea{
                                height: get-vw(75px);
                            }
                        }
                        .botones{
                            width: 100%;
                            display:flex;
                            text-align: left;
                            position:relative;
                            flex-wrap: nowrap;
                            margin-bottom: get-vw(20px);
                            justify-content: flex-start;
                            .btn{
                                font-size: get-vw(22px);
                                float:right;
                                text-align: center;
                                padding: 10px 10px;
                                background:$grisoscuro;
                                border-radius: 3px;
                                color:#fff;
                                font-family: $helveticaBd;
                                 width: get-vw(293px);
                            }
                            .campologo{
                                width: get-vw(290px);
                                height: get-vw(40px);
                                border:1px solid #CCCCCC;
                                border-radius: 3px;
                                font-size: get-vw(22px);
                                color:$grisoscuro;
                                background: $grisclaro;
                                padding: get-vw(10px)
                            }
                            .formatos{
                                p{
                                    font-size:get-vw(12px);
                                    line-height: get-vw(15px);padding-left: get-vw(20px);
                                }
                            }
                        }
                        &:last-child{
                            margin-left: get-vw(40px);
                        }
                        .contentModelo{
                            display: inline-block;
                            width: 100%;
                            border: 1px solid $grisCCC;
                            width: get-vw(525px);
                            background-color:#fff;
                            .modelo{
                                display: flex;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                .bloqImage{min-width:get-vw(260px); }
                                .bloqInfo{
                                    text-align: center;
                                    padding: get-vw(10px);
                                     color:#000;
                                    h6{
                                       font-size: get-vw(30px);
                                       font-family: $helveticaBd;
                                       line-height: get-vw(34px);
                                       margin-top: 0px;
                                       margin-bottom: get-vw(15px);

										p{
                                        font-size: get-vw(30px);
										line-height: get-vw(34px);
                                    	}
                                    }
                                    p{
                                       font-size: get-vw(15px);
                                       line-height: get-vw(22px);
                                        margin-bottom: get-vw(15px);
                                    }
                                }
                            }
                            .notita{
                                color:#000;font-size: get-vw(13px);
                                       line-height: get-vw(16px);
                                       padding-left:  get-vw(10px);
                            }
                            .by{
                                color:#000;
                                font-size: get-vw(12px);
                                line-height: get-vw(16px);
                                text-align: right;
                                padding-right: get-vw(10px);
                                margin-bottom:get-vw(10px);
                                min-height: 16px;
                            }
                            .forceWrap{
                                overflow-wrap: break-word;
                                word-wrap: break-word;
                                hyphens: auto;
                            }
                        }


                        .contentCheckbox{
                            float:left;
                            font-size: get-vw(18px);
                        }
                    }
                    .parte2{
                        .btn{
                                    font-size: get-vw(22px);
                                    float:left;
                                    text-align: center;
                                    padding: 10px 20px;
                                    background: linear-gradient(to left,$morado1, $morado2);
                                    border-radius: 30px;
                                    color:#fff;
                                    font-family: $helveticaBd;
                                     width: get-vw(230px);
                                     margin-top: get-vw(20px);
                                }
                    }
                }
                .nota{
                     font-size: get-vw(18px);
                     text-align: left;
                     padding: get-vw(20px);
                     margin-top: 0px;
                     padding-top: 0px;
                     width: 100%;
                }
            }
            .bloque1{
                max-width: get-vw(1050px);
                margin-right: get-vw(80px);
                margin-bottom: get-vw(20px);
                .btnGoToStep2.btn {
              		margin-top: 10px;
              		font-size: 22px;
              		float: right;
              		text-align: center;
					padding: 10px 20px;
					background: linear-gradient(to left,$morado1, $morado2);
              		border-radius: 30px;
              		color: #fff;
              		font-family: "HelveticaNeueLTStd-Bd";
              		width: 180px;
              		line-height: 38px;
          		}
            }
            .bloque2{
                max-width: get-vw(1320px);
                opacity:0;
                display:none;
                .headerProceso{
                    padding-right: 2%;
                    h3{
                        padding-left:  get-vw(20px);
                    }
                }
            }
            .bloque3{
                .headerProceso{
                    padding-right: 2%;
                    padding-left:  get-vw(20px);
                }
                .contentLogin{
                    display: inline-flex;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    .inicioLogin{
                        width: 100%;
                        max-width: get-vw(480px);
                        margin-top: 5%;
                        border:1px solid $grisCCC;
                        -webkit-box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                            -moz-box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                            box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                        .contentFormLogin{
                            display: inline-block;
                            padding: 2% 10% 10% 10%;
                            width: 80%;
                            h5{
                                font-size:get-vw(26px);
                                line-height: get-vw(35px);
                                color:$azul;
                                font-family: $helveticaBd;
                                padding-left: 0px;
                            }
                            .campo{
                                display: inline-block;
                                margin-bottom: get-vw(20px);
                                width: 100%;
                                input{
                                    width: 90%;
                                    padding: 5%;
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;
                                    background: $grisclaro;
                                }
                                .char-count{font-size: 14px;}
                            }
                            .item{display: inline-block;width: 100%;font-size:get-vw(18px);color:$grisoscuro; }
                            .btn{
                                    font-size: get-vw(22px);
                                    float:left;
                                    text-align: center;
									padding: 10px 0px 10px 0px;
									background: linear-gradient(to left,$morado1, $morado2);
									border-radius: 30px;
                                    color:#fff;
                                    font-family: $helveticaBd;
                                    width: 100%;
                                    margin-top: get-vw(20px);

                                }
                                .olvido{

                                       font-family: $helveticaBd;
                                       font-size:get-vw(18px);
                                       color:$grisoscuro;
                                       text-decoration: underline;
                                       float: right;
                                       margin-right: 5%;
                                       margin-top:get-vw(22px);
                                }
                        }
                        .divisionLogin{
                            border-top:1px solid $grisCCC;
                            padding: 5%;
                            a{font-size:get-vw(18px);color:$gris;}
                            .semicentrado{float: right;margin-right: 10%;margin-bottom: 5%;}
                        }
                    }
                }
            }
            .bloque4{
                .inicioLogin{
                    width: 100%;
                    max-width: get-vw(895px)!important;
                    .contentFormLogin{
                        display: flex;
                        flex-wrap: wrap;
                        .campo{max-width: 48%;
                               &:nth-child(2n+1){margin-left: 2%;}
                        }
                        .checkfactura{margin-bottom:get-vw(20px)}
                    }
                }
            }
            .bloque5{
                .headerProceso{
                    padding-right: 2%;
                    padding-left:  get-vw(20px);
                }
                .contentPedido{
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    padding: get-vw(20px);

                    align-items: flex-start;
                    h4{font-size: get-vw(26px);font-family: $helveticaBd;color:$azul;}
                    .pedido{
                        margin-right: 5%;
                        width: 100%;
                        max-width:get-vw(930px);
                        h4{
                            width: 100%;
                            border-bottom: 1px solid $grisCCC;
                            padding-bottom: get-vw(20px);
                        }
                        .lineas{
                            display: flex;
                            width: 100%;
                            /*width:get-vw(930px);  */
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            margin-top: get-vw(20px);

                            align-items: flex-start;
                            .item{

                                p{
                                    line-height: get-vw(30px);
                                    font-size: get-vw(22px);
                                    span{font-family: $helveticaBd;}
                                }
                            }
                            .item1{
                                width: 100%;
                                max-width:get-vw(185px);
                            }
                            .item2{
                                width: 100%;
                                max-width:get-vw(369px);
                            }
                            .item3{
                               width: 100%;
                                 max-width:get-vw(70px);
                            }
                            .item4{
                               width: 100%;
                                 max-width:get-vw(100px);
                            }
                            .item5{
                               width: 100%;
                               max-width:get-vw(100px);
                            }
                            .item6{
                               width: 100%;
                               max-width:get-vw(100px);
                            }

                            .infototales{
                                         p{
                                             text-align: left;font-size: get-vw(20px);
                                         }
                            }
                            .totales{
                                     p{
                                         text-align: right;font-size: get-vw(20px);
                                     }
                            }
                            .total{text-align: left;font-size: get-vw(30px);color:$azul;font-family: $helveticaBd;}
                            .preciofinal{text-align: right;font-size: get-vw(30px);color:$azul;font-family: $helveticaBd;}
                        }
                        .subtotales{border-top: 1px solid $grisCCC;padding-top: get-vw(10px);}
                    }
                    .datos{
                        width: 100%;
                        max-width:get-vw(530px);
                        p{
                          font-size: get-vw(22px);
                          line-height: get-vw(35px);
                        }
                        .metodo{
                                margin-top: get-vw(50px);

                        }
                        .minitexto{
                            font-size: get-vw(18px);
                          line-height: get-vw(24px);

                        }
                        img{margin-top: get-vw(30px);  }
                        .btn{
                            margin-top: get-vw(40px);
                            margin-bottom: get-vw(40px);
                                    font-size: get-vw(22px);
                                    float:left;
                                    text-align: center;
                                    padding: 10px 20px;
                                    background: linear-gradient(to left,$morado1, $morado2);
                                    border-radius: 30px;
                                    color:#fff;
                                    font-family: $helveticaBd;
                                    width: get-vw(185px);
                                    img{margin-top: 0px;}
                                }
                                .seguircomprando{
                                    float:left;font-size: get-vw(18px);
                                    text-decoration: underline;
                                    margin-top: get-vw(0px);
                                    color:$grisoscuro;
                                }
                    }
                }
            }
        }
    }
    .proceso{
        overflow-x: auto;
    }
}

#wraper{
    .main-sectors{
        .contentHome{
            display:inline-flex;
            text-align: left;;
            position:relative;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin: auto;
            align-items: top;
            margin-bottom: get-vw(40px);
            justify-content: space-between;
            .bloquecitos{
                max-width: 30%;
                text-align: center;
                img{
                    margin-bottom: get-vw(40px);
                }
                h6{
                     font-size: get-vw(30px);
                    line-height: get-vw(35px);
                    font-family: $helveticaBd;
                    color:$azul;
                     margin-bottom: get-vw(20px);
                }
                p{font-size: get-vw(22px);  }
                margin-bottom: get-vw(40px);
            }
			
			.flechabloquecito{
			   min-height:200px;
			
			}

        }
        .btnComenzar{
            margin-bottom: get-vw(30px);
            float:right;
            .btn{
                margin-top: get-vw(40px);
                margin-bottom: get-vw(40px);
                font-size: get-vw(22px);
                float:left;
                text-align: center;
                padding: 10px 10px;
                border:2px solid $morado2;
                border-radius: 30px;
                color:$morado2;
                font-family: $helveticaBd;
                width: get-vw(195px);
                img{margin-top: 0px;}
            }
        }
        .titular{
                h3{
                    font-size: get-vw(30px);
                    line-height: get-vw(35px);
                    font-family: $helveticaBd;
                    color:$azul;
                }
                p{font-size: get-vw(22px);  }
                margin-bottom: get-vw(40px);
            }
    }
    .max1200{
        max-width: get-vw(1210px)!important;
    }
}

#wraper{
    .main-sectors-ancho-completo{
         text-align: center;
        display: inline-block;
        width: 100%;
        padding-top: get-vw(50px);
        padding-bottom: get-vw(50px);
        background: $grisclaro;
        margin-bottom: get-vw(50px);
        max-width: 100%;
         .titular{
                p{
                    font-size: get-vw(30px);
                    line-height: get-vw(35px);
                    color:$azul;
                    b{
                        font-family: $helveticaBd;
                    }
                    a{color:$rojo;text-decoration: underline;}
                }

            }
    }
}
.contacto{
    margin-top: get-vw(50px);
    max-width:get-vw(1200px)!important;
     display:inline-flex;
            text-align: left;;
            position:relative;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin: auto;
            align-items: top;
            margin-bottom: get-vw(40px);
            justify-content: space-between;
            .titular{
                margin-top: get-vw(40px);
                width: 100%;
                 text-align: center;;
                h3{
                    font-size: get-vw(30px);
                    line-height: get-vw(35px);
                    font-family: $helveticaBd;
                    color:$azul;
                    margin-bottom: get-vw(20px);
                }
                p{font-size: get-vw(22px);  }
                margin-bottom: get-vw(40px);
            }
            .content{
                width: 100%;
                                max-width:100%;
                                display: flex;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                align-items: flex-start;
                                font-size: get-vw(18px);
                        .campo{
                            margin-bottom: get-vw(20px);
                                 width: 100%;
                                .btn{
                                    font-size: get-vw(22px);
                                    float:left;
                                    text-align: center;
									padding: 10px 20px;
									border-radius: 30px;
                                    cursor: pointer;
                                    color:#fff;
                                    font-family: $helveticaBd;
                                    width: get-vw(180px);
                                    text-align: left;
                                    background: url(../img/flechaenviar.png)  no-repeat 90% center, linear-gradient(to left,$morado1, $morado2);
                                }
                                input{
                                    width: 96%;
                                    height: get-vw(60px);
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;
                                    background: $grisclaro;
                                    padding-left: 2%;
                                }
                                textarea{
                                    width: 97%;
                                    height: get-vw(300px);
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;
                                    background: $grisclaro;
                                    padding-left: 2%;
                                    padding-top: 2%;
                                    font-family: $helveticaRoman;
                                }
                                select{
                                    width: 99.3%;
                                    height: get-vw(65px);
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;
                                }

                            }
            }
}
.preguntas{
    margin-top: get-vw(50px);
    max-width:get-vw(1200px)!important;
     display:inline-flex;
            text-align: left;;
            position:relative;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin: auto;
            align-items: top;
            margin-bottom: get-vw(40px);
            justify-content: space-between;
     .titular{
                margin-top: get-vw(40px);
                width: 100%;
                 text-align: center;;
                h3{
                    font-size: get-vw(30px);
                    line-height: get-vw(35px);
                    font-family: $helveticaBd;
                    color:$azul;
                    margin-bottom: get-vw(40px);
                }
                margin-bottom: get-vw(40px);
            }
            #accordion{
                h3{
                    font-size: get-vw(26px);
                    line-height: get-vw(32px);
                    color:$azul;
                    margin-bottom: get-vw(32px);
                    cursor: pointer;
                    &:after{content: " +";}
                }
                div{
                    font-size: get-vw(22px);
                }
                .ui-accordion-header-active{
                    color:$rojo!important;
                    font-family: $helveticaBd;
                    &:after{content: " -";}
                }
            }
}

#wraper{
    display:inline-block;
    text-align: center;
    width: 100%;
    .main-sectors{
        text-align: center;
        display: inline-block;
        width: 100%;
        max-width: get-vw(1680px);
        .contentCentro{
            display:inline-flex;
            text-align: left;;
            position:relative;
            flex-wrap: wrap;
            width: 100%;
            margin: auto;
            align-items: center;
            justify-content: space-around;
            .itemCentro{
                width: 100%;
                font-size: get-vw(23px);
                line-height: get-vw(26px);
                max-width: 48%;
                margin-top: 5%;
                text-align: center;
                h2{
                    font-size: get-vw(30px);
                    line-height: get-vw(35px);
                    margin-top: 3%;
                    font-family: $helveticaBd;
                    color:$azul;
                }
                p{
                    font-size: get-vw(22px);
                    line-height: get-vw(35px);
                }
                a{color:$grisoscuro;}
                .btn{
                    font-size: get-vw(22px);
                    padding: 10px 10px;
                    background:#E8E8E8;
                    border-radius: 30px;
                    color:$grisoscuro;
                    font-family: $helveticaBd;
                    margin-top: get-vw(50px);
                    display: inline-block;
                    width: 100%;
                    max-width: get-vw(273px);
                }
                span{color:$grisoscuro;}
            }
            .fallo{
                margin-top: 2%;
            }
        }
    }
}



#wraper2{
    display:inline-block;
    text-align: center;
    width: 100%;
    .headerProceso{
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: get-vw(-30px);
            height: get-vw(100px);
            color:$azul;
            h3{
                font-size: get-vw(30px);
                line-height: get-vw(35px);
                font-family: $helveticaBd;
            }
            .bloquecentrado{
                 width: 100%;
                 max-width: get-vw(1680px);
                 display: inline-block;
                 text-align: left;
            }
        }
    .main-sectorsCuenta{
        text-align: center;
        display: inline-block;
        width: 100%;
        max-width: get-vw(1920px);

        .contentCentro{
            display:inline-flex;
            text-align: left;;
            position:relative;
            flex-wrap: wrap;
            width: 100%;
            margin: auto;
            align-items: center;
            justify-content: center;
            .itemCentro{
                width: 100%;
                max-width: get-vw(480px);
                font-size: get-vw(23px);
                line-height: get-vw(26px);
                max-width: 48%;
                margin-top: 3%;
                text-align: center;
               .contentLogin{
                    display: inline-flex;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    .inicioLogin{
                        width: 100%;
                        max-width: get-vw(480px);
                        margin-top: 2%;
                        background: #fff;
                        border:1px solid $grisCCC;
                        -webkit-box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                            -moz-box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                            box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                        .contentFormLogin{
                            display: inline-block;
                            padding: 2% 10% 10% 10%;
                            width: 80%;
                            h5{
                                font-size:get-vw(26px);
                                line-height: get-vw(35px);
                                color:$azul;
                                font-family: $helveticaBd;
                                padding-left: 0px;
                                text-align: left;
                                padding-bottom: get-vw(15px);
                                padding-top: get-vw(15px);
                            }
                            .campo{
                                display: inline-block;
                                margin-bottom: get-vw(20px);
                                width: 100%;
                                input{
                                    width: 90%;
                                    padding: 5%;
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;
                                    background: $grisclaro;
                                }
                            }
                            .item{display: inline-block;width: 100%;font-size:get-vw(18px);color:$grisoscuro; }
                            .btn{
                                    font-size: get-vw(22px);
                                    float:left;
                                    text-align: center;
									padding: 10px 0px 10px 0px;
									background: linear-gradient(to left,$morado1, $morado2);
									border-radius: 30px;
                                    color:#fff;
                                    font-family: $helveticaBd;
                                    width: 100%;
                                    margin-top: get-vw(20px);

                                }
                                .olvido{

                                       font-family: $helveticaBd;
                                       font-size:get-vw(18px);
                                       color:$grisoscuro;
                                       text-decoration: underline;
                                       float: right;
                                       margin-right: 5%;
                                       margin-top:get-vw(22px);
                                }
                        }
                        .divisionLogin{
                            border-top:1px solid $grisCCC;
                            padding: 5%;
                            a{font-size:get-vw(18px);color:$gris;}
                            .semicentrado{float: right;margin-right: 10%;margin-bottom: 5%;}
                        }
                        .textoeliminnar{
                            text-align: left;
                            font-size: get-vw(22px);
                            margin-bottom: get-vw(20px);
                            line-height:  get-vw(35px);
                            b{
                                color:$grisoscuro;
                                font-family: $helveticaBd;
                            }
                        }
                    }
               }
            }
        }
        .bloqueexpandido{
            width: 100%;
            text-align: left;
            margin-top: get-vw(0px);
            max-width: get-vw(1680px);
            a{
                font-size: get-vw(18px);
                line-height: get-vw(30px);
                color:$grisoscuro;
                text-decoration: underline;
            }
        }
        .contentExpandido{
            display: inline-flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: space-between;

            width: 100%;
            .bloque{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                background: #fff;
                justify-content: center;
                width: 100%;
                .lineas{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    width: 100%;

                    padding-bottom: get-vw(40px);
                    text-align: left;
                    p{
                        line-height: get-vw(35px);
                        font-size: get-vw(22px);
                        span{
                            font-family: $helveticaBd;
                        }
                        a{font-family: $helveticaBd;  float:left;
                          sup{
                              float:right;
                              color:#fff;
                              background: $rojo;
                              text-align: center;
                              font-size: 10px;
                              line-height: 9px;
                              width: 10px;
                              height: 10px;
                              border: 3px solid $rojo;
                              -moz-border-radius: 50%;
                              -webkit-border-radius: 50%;
                              border-radius: 50%;

                          }
                        }
                    }
                    .item2{margin-left:get-vw(20px);  }
                     .btn{
                                font-size: get-vw(22px);
                                float:left;
                                text-align: center;
                                padding: 10px 10px;
                                background:#fff;
                                border-radius: 30px;
                                border: 2px solid $rojo;
                                color:$rojo;
                                font-family: $helveticaBd;
                                width: 100%;
                                width: get-vw(277px);
                                img{margin-top: 0px;}
                                }


                }
                .activo{
                    background: url(../img/flechaverde.png)  no-repeat center right $grisclaro;;
                }
                .empresa{
                        p{
                            color:$azul;
                            font-size: get-vw(22px);
                        }
                        .empresatitulo{
                            font-family: $helveticaBd;
                            margin-bottom: get-vw(20px);
                        }
                        .fecha{text-align: center;color:$gris;font-size:get-vw(18px); }
                }
                .cliente{
                        text-align: right;
                        p{
                            font-size: get-vw(22px);
                        }
                        .clientetitulo{
                            font-family: $helveticaBd;
                            margin-bottom: get-vw(20px);
                        }
                        .fecha{text-align: center;font-size:get-vw(18px);}
                }
                .formulario{
                    background: $grisclaro;
                    .item{
                        width: 100%;
                        textarea{
                            display: block;
                            width: 96%;
                            text-align: left!important;
                            font-family: $helveticaRoman;
                            font-size: get-vw(22px);
                            color:$grisoscuro;
                            padding: 2%;
                            border: none;
                            background: $grisclaro;
                        }
                        .btnMsj{
                            width: 35px;
                            height: 35px;
                            float:right;
                            background: url(../img/btonenviarmnsj.png)  no-repeat center center transparent;;
                            border:none;
                            cursor: pointer;
                        }
                    }
                }
                .cabecera{
                     justify-content: space-between;
                     padding-top: get-vw(40px);
                     border-top: 1px solid $grisCCC;
                     &:first-child{border: none;}
                     p{color:#000;}
                }
                .contenerdomaximo{
                    width: 100%;
                    max-width: get-vw(1680px);
                    display: inline-block;
                    text-align: left;
                }
                .bloquecentrado{max-width: get-vw(1680px);}
                .headerProceso .abierto{width: 100%;max-width: get-vw(840px);;}

            }
            .bloque1{
                text-align: right;
                .contenerdomaximo{
                    text-align: left;;
                    .pedido{display: inline-block;text-align: left;width: 100%;}

                }
                .pedido{
                            width: 100%;
                            .lineas{
                                padding: get-vw(20px);;
                                margin-bottom: get-vw(20px);;
                                margin-left: get-vw(-20px);;
                            }
                        }
                .abierto{
                        .pedido{
                            width: 100%;max-width: get-vw(840px);;
                            .lineas{
                                 width: 90%;
                    padding: 5%;
                            }
                        }
                    }

            }
            .bloque2{
                position: absolute;
                right: 0;
                max-width: 50%;
                text-align: left;
                 .contenerdomaximo{
                    text-align: left;
                    .pedido{display: inline-block;text-align: left;width: 100%;}

                 }
                 .abierto{
                        .pedido{
                            width: 100%;max-width: get-vw(840px);;
                            .lineas{
                                 width: 90%;
                    padding: 5%;
                            }
                        }
                    }
                    .headerProceso{
                        .item{
                           width: 100%;max-width: 89%;
                           a{float:right;}
                           h3{float:left;}
                        }
                    }
            }
        }
    }
}

.contentLegales{
    text-align: left;
    h2{
        font-size: get-vw(30px);;
        color:$grisoscuro;
        font-family: $helveticaBd;
        text-align: center;
        margin-bottom: get-vw(60px);;
        margin-top:get-vw(30px);;
    }
    h3{
        font-size: get-vw(26px);;
        color:$azul;
        margin-bottom: get-vw(40px);;
        margin-top:get-vw(30px);;
    }
    p{
        font-size: get-vw(22px);;
    }
}





.select-css {
	display: block;
	line-height: 1.3;
	padding: .4em 1.4em .3em .8em;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border-radius: 3px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background: url(../img/select.png)  no-repeat center right #F8F8F8;
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: #888;
}
.select-css:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.select-css option {
	font-weight:normal;
}







/*#wraper{
    display:inline-block;
    text-align: center;
    width: 100%;
    .headerProceso{
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: get-vw(-30px);
            height: get-vw(100px);
            color:$azul;
            h3{
                font-size: get-vw(30px);
                line-height: get-vw(35px);
                font-family: $helveticaBd;
            }
            .bloquecentrado{
                 width: 100%;
                 max-width: get-vw(1680px);
                 display: inline-block;
                 text-align: left;
            }
        }
    .main-sectorsCuenta{
        text-align: center;
        display: inline-block;
        width: 100%;
        max-width: get-vw(1920px);

        .contentCentro{
            display:inline-flex;
            text-align: left;;
            position:relative;
            flex-wrap: wrap;
            width: 100%;
            margin: auto;
            align-items: center;
            justify-content: center;
            .itemCentro{
                width: 100%;
                max-width: get-vw(480px);
                font-size: get-vw(23px);
                line-height: get-vw(26px);
                max-width: 48%;
                margin-top: 3%;
                text-align: center;
               .contentLogin{
                    display: inline-flex;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    .inicioLogin{
                        width: 100%;
                        max-width: get-vw(480px);
                        margin-top: 2%;
                        background: #fff;
                        border:1px solid $grisCCC;
                        -webkit-box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                            -moz-box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                            box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                        .contentFormLogin{
                            display: inline-block;
                            padding: 2% 10% 10% 10%;
                            width: 80%;
                            h5{
                                font-size:get-vw(26px);
                                line-height: get-vw(35px);
                                color:$azul;
                                font-family: $helveticaBd;
                                padding-left: 0px;
                                text-align: left;
                                padding-bottom: get-vw(15px);
                                padding-top: get-vw(15px);
                            }
                            .campo{
                                display: inline-block;
                                margin-bottom: get-vw(20px);
                                width: 100%;
                                input{
                                    width: 90%;
                                    padding: 5%;
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;
                                    background: $grisclaro;
                                }
                            }
                            .item{display: inline-block;width: 100%;font-size:get-vw(18px);color:$grisoscuro; }
                            .btn{
                                    font-size: get-vw(22px);
                                    float:left;
                                    text-align: center;
									padding: 10px 0px 10px 0px;
									background: linear-gradient(to left,$morado1, $morado2);
									border-radius: 30px;
                                    color:#fff;
                                    font-family: $helveticaBd;
                                    width: 100%;
                                    margin-top: get-vw(20px);

                                }
                                .olvido{

                                       font-family: $helveticaBd;
                                       font-size:get-vw(18px);
                                       color:$grisoscuro;
                                       text-decoration: underline;
                                       float: right;
                                       margin-right: 5%;
                                       margin-top:get-vw(22px);
                                }
                        }
                        .divisionLogin{
                            border-top:1px solid $grisCCC;
                            padding: 5%;
                            a{font-size:get-vw(18px);color:$gris;}
                            .semicentrado{float: right;margin-right: 10%;margin-bottom: 5%;}
                        }
                        .textoeliminnar{
                            text-align: left;
                            font-size: get-vw(22px);
                            margin-bottom: get-vw(20px);
                            line-height:  get-vw(35px);
                            b{
                                color:$grisoscuro;
                                font-family: $helveticaBd;
                            }
                        }
                    }
               }
            }
        }
        .bloqueexpandido{
            width: 100%;
            text-align: left;
            margin-top: get-vw(0px);
            max-width: get-vw(1680px);
            a{
                font-size: get-vw(18px);
                line-height: get-vw(30px);
                color:$grisoscuro;
                text-decoration: underline;
            }
        }
    }
}*/



                        .contentModeloImpresion{

                            display: inline-block;
                            width: 100%;
                            border: 1px solid $grisCCC;
                            width: 1500px;
							background-color:#ffffff;
                            .modelo{
                                display: flex;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                .bloqImage{width:50%;
									img{width:100%;}
								}
                                .bloqInfo{
                                    text-align: center;
                                    padding: get-vw(10px);
                                    color:#000;
									width:50%;
                                    h6{

                                       font-size: 90px;
                                       font-family: $helveticaBd;
                                       line-height: 90px;
                                       margin-top: 50px;
                                       margin-bottom: 40px;
                                    }
                                    p{
                                       font-size: 46px;
									   line-height: 56px;
                                       margin-bottom: 46px;
                                    }
									img{width:auto; height:180px}
                                }
                            }
                            .notita{
                                color:#000;
                                font-size: 38px;
							    line-height: 40px;
                                padding-left:  2%;
								text-align:left;
								margin-top:20px;
                            }
                            .by{
								color:#000;
                                font-size: 30px;
							    line-height: 35px;
							    text-align: right;padding-right:  2%;
								margin-bottom:2%;
								margin-top:20px;
								}
                        }



                        .piezaOnline{
                            .modelo{
                                .bloqInfo{
                                    p{
                                       font-size: 62px;
									   line-height: 62px;
                                    }
									img{width:auto; height:180px}
                                }
                            }
                            .notita{
                                font-size: 52px;
							    line-height: 52px;
                            }
                            .by{
                                font-size: 40px;
							    line-height: 35px;
								}
                        }


.menumicuenta{
	margin-top:10px !important;
}