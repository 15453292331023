@media screen and (max-width: 1300px){
footer{ 
        font-size: get-vw-portatil(20px); 
        margin-top: get-vw-portatil(50px); 
        max-width: get-vw-portatil(1200px);        
    .content{     
      .columna{         
          margin-right: get-vw-portatil(40px); ;        
          .items{
              margin-bottom: get-vw-portatil(10px);
            b{              
                margin-bottom: get-vw-portatil(20px); 
            }
        }
      }
        
    }
    
}
}