@media screen and (max-width: 600px){
#main-header{  
    margin-bottom: get-vw-mvl(30px); 
    .content-header{        
        width: 100%;
        height: get-vw-mvl(60px); 
        max-width: 100%; 
        .btnMenu{
            font-size:get-vw-mvl(22px);
			.logoMobile{
				width:get-vw-mvl(170px); 
			}
        }
        nav{        
            width: 100%;
            max-width: 70%;
            ul{
                li{
                   
                    padding-right: get-vw-mvl(20px);;
                    a{font-size:get-vw-mvl(22px);
                      &:hover{color:$rojo;}
                    }
					img{
						width:get-vw-mvl(170px); 
					}
                    .active{font-weight: 800;background: none!important;}
                }
            }
        }
        .headerDerecho{         
            width: 100%;
            padding-right: 5%;
            max-width: 30%;
            ul{              
                li{        
                    text-align: center;
                    a{font-size:get-vw-mvl(22px);                     
                      padding: 7px 10px;
                      border-radius: 5px;
                      background: #fff;;
                      &:hover{color:$rojo;}
                    }
                    &:last-child{padding-left: get-vw-mvl(20px);;}
                    .carrito1{                     
                        margin-top: get-vw-mvl(16px);
                    }
                    .carrito2{
                        font-size: get-vw-mvl(14px);                      
                        margin-top: get-vw-mvl(-10px);
                    }
                    .carrito3{
                        text-align: center;
                        display: block;
                        color:$rojo;
                         font-size: get-vw-mvl(15px); 
                          margin-top: get-vw-mvl(-12px);
                          margin-bottom: get-vw-mvl(-15px);
                    }
                }
            }
            
        }
        .contentLogin{               
                top:get-vw-mvl(65px);             
                .inicioLogin{
                        width: 100%;
                        max-width: 100%;   
                        margin-top: 0%;   
                        border-top: none;
                        -webkit-box-shadow: 0px 2px 5px 0px rgba(112,112,112,1);
                            -moz-box-shadow: 0px 2px 5px 0px rgba(112,112,112,1);
                            box-shadow: 0px 2px 5px 0px rgba(112,112,112,1);
                        .contentFormLogin{                         
                            padding: 8% 10% 10% 10%;
                            width: 80%;                            
                            h5{
                                font-size:get-vw-mvl(26px);   
                                line-height: get-vw-mvl(35px);                                 
                                padding-left: 0px;
                            }
                            .campo{                              
                                margin-bottom: get-vw-mvl(20px);
                                width: 100%;                                
                                input{
                                    width: 90%;
                                    padding: 5%;                                   
                                    font-size: get-vw-mvl(22px);                                  
                                }
                            }
                            .item{width: 100%;font-size:get-vw-mvl(18px); }
                            .btn{
                                    font-size: get-vw-mvl(22px);                                  
                                    padding: 10px 0px 10px 0px;                                   
                                    border-radius: 3px;                                   
                                     width: 100%;
                                     margin-top: get-vw-mvl(20px);
                                     
                                }
                                .olvido{                                                                         
                                       font-size:get-vw-mvl(18px);                                      
                                       margin-right: 5%;
                                       margin-top:get-vw-mvl(22px);  
                                }
                        }
                        .divisionLogin{                          
                            padding: 5%;
                            a{font-size:get-vw-mvl(18px);}
                            .semicentrado{margin-right: 10%;margin-bottom: 0%;}
                        }
                    }
            }
            .contentLogueado{
                position: absolute;
                 width: 100%;
                        min-width: 100%;   
                top:get-vw-mvl(65px);                      
                .inicioLogin{   
                    border-top: none;
                        width: 100%;
                        min-width: 100%;   
                        margin-top: 0%;                      
                        .contentFormLogin{                          
                            padding: 8% 10% 10% 10%;
                            width: 80%;                                                       
                            a{                               
                                margin-bottom: get-vw-mvl(10px);
                                line-height: get-vw-mvl(30px);      
                                font-size: get-vw-mvl(22px);                               
                                &:last-child{margin-bottom: 0px;}
                            }
                        }
                        .divisionLogin{                          
                            padding: 5%;
                            a{font-size:get-vw-mvl(18px);}
                            .semicentrado{display: inline-block;margin-right: 10%;margin-bottom: 0%;}
                        }
                    }
            }
    }
    .contentmenumvl{
                
                top:get-vw-mvl(65px);            
                .menumvl{    
                     padding-top: get-vw-mvl(20px);
                     padding-bottom: get-vw-mvl(20px);
                            ul{
                                li{                                 
                                    a{                                      
                                       width: 100%; 
                                       font-size:get-vw-mvl(22px);
                                       padding-top: get-vw-mvl(15px);
                                       padding-bottom: get-vw-mvl(15px);
                                       &:hover{color:$rojo!important;}
                                    }
                                 &:hover{color:$rojo!important;}   
                                }
                                &:hover{color:$rojo!important;}
                            }
                    }
            }
}
}
